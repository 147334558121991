.background-test-whatsOn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  padding: 20px;
  box-sizing: border-box;

  /* Background Image */
  background: url("../assets/WhatsUpBG.jpg") no-repeat center center fixed;
  background-size: cover;
  background-position: center;
  
  overflow: hidden; /* Prevent scrolling */
}

/* Container to center the content and make it responsive */
.image-container-whatsOn {
  max-width: 1200px; /* Limit the maximum width of the content */
  width: 100%; /* Make the content responsive */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Centering the content in the middle */
.whatsOn-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 800px; /* Maximum width to avoid over-expanding */
  padding: 20px;
  box-sizing: border-box;
}

/* Style for each item, keeping it responsive */
.whatsOn-item {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Container for image and caption with a white border */
.whatsOn-img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 4px solid transparent; /* Default to no border */
  margin-bottom: 15px;
}

/* Apply white border if caption exists */
.whatsOn-img-container.with-caption {
  border-color: white; /* White border around both image and caption */
  background-color: white; /* Set white background to make the caption stand out */
}

.whatsOn-img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}



/* Caption styling inside the white box */
.whatsOn-caption {
  font-size: 1.2rem;
  color: #000; /* Black text to contrast against white background */
  margin-bottom: 0;
  text-align: center; /* Center the caption below the image */
}

@media (max-width: 768px) {
  .whatsOn-content {
    padding: 10px;
  }

  .whatsOn-img {
    width: 100%; /* Ensure images are fully responsive on smaller screens */
    max-width: none;
  }

  .whatsOn-caption {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}
