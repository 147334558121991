html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

.listenNowContainer {
    background: url("../assets/ListenNowBG.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow-x: hidden;
    background-attachment: fixed;
     height: 100vh;
}



.whatson {
    background: url("../assets/WhatsUpBG.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow-x: hidden;
    background-attachment: fixed;
     height: 100vh;
}

.stream-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    font-family: 'Amatic SC', cursive;
    font-size: 1.5rem;
    margin-left: 400px;
    margin-top: -100px;
}

.streamSidebuttons {
      position: absolute;
  left: 100px;
  top: 10px;
}

.buttons-listen-now {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom:-150px;

    @media (max-width: 700px) {
        flex-direction: column;
        }
}

.buttons-listen-now img {
    width: 300px;
    margin-top: -100px;
}

#whatsUp {
    margin-top: -250px;
    cursor: pointer;
    @media (max-width: 700px) {
        margin-top: 0;
        z-index: 101010;
    }
}

#whatsUp:hover {
    transform: skewY(10deg);
}

#archive {
    cursor: pointer;
    margin-top: -150px;
    @media (max-width: 700px) {
        margin-top: -130px;
        margin-bottom: -20px;
        padding-top: 0;
        padding-bottom: 0;
    }
}

#archive:hover {
    transform: skewY(10deg);
}

.schedule-container {
    margin: 20px;
    width: 650px;
    height: 400px;
    max-width: 750px;
    max-height: 400px;
    overflow: scroll;
    
}

.stream-container p {
    padding: 20px;
}

.stream-player {
    width: 28rem;
    margin-top: -130px;
}


.listenNowContainer {
    display: grid;
  grid-template-columns: 1fr 3fr; /* 1/4 for the sidebar, 3/4 for the content */
  grid-template-rows: 100%;
  grid-template-areas:
    "sidebar content"
    "footer footer";
    height: 100vh;
    padding-top: 50px;
    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.sidebar {
    grid-area: sidebar;
    height: 75%;
    max-height: 800px;
    width: 100%;
    @media (max-width: 700px) {
        display: none;
        }
  }

.content {
    max-width: 800px;
    grid-area: content;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: flex-start;
   padding-top: 80px;
   @media (max-width: 700px) {
    margin-top: -90px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    }
}

.listen-footer {
    grid-area: footer;
    height: 25%;
    margin-top: -60px;
    z-index: 5;

    /* remove when we get the footer working  */
    display: none;
}

.whatsUpPoster {
    width: 500px;
    height: 500px;
    position: absolute;
    right: 43%;
    z-index: 1010;
}

.whatsUpPoster img{
    width: 700px;
    /* if poster needs to be rotated vertically */
    /* transform: rotate(90deg);  */
}

.whatsUpPosterNone {
    display: none;
}


.donateMobileButton {
    display: none;
    @media (max-width: 700px) {
        display: flex;
        margin-top: -100px;
        margin-bottom: 60px;
    }
}


.tock-class {
    min-height: 100%;
    min-width: 100%;
}