.side_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .2rem;
    margin-left: 20px;
    height: 750px;
    @media (max-width: 600px) {
        display: none;
    }
}

.button-wood-plank {
    justify-content: center;
    min-height: 3rem;
    min-width: 15rem;
    background: none;
	color: whitesmoke;
	border: none;
	padding: 0;
	font-family: 'Amatic SC', cursive;
	cursor: pointer;
	outline: inherit;
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

#back {
    background: url("../assets/Back_Plank.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100px;
}

#back:hover {
    transform: skewY(10deg);
}

#home {
    background: url("../assets/Home_Plank.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100px;
}

#home:hover {
    transform: skewY(10deg);
}

#about {
    background: url("../assets/planks_panda/About_Plank.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 85px;
}

#about:hover {
    transform: skewY(10deg);
}

#id-0 {
    background: url("../assets/Listen_Now_Plank.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100px;
}

#id-0:hover {
    transform: skewY(10deg);
}

#id-1 {
    background: url("../assets/Donate_Plank.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100px;
}

#id-1:hover {
    transform: skewY(10deg);
}

#id-2 {
    background: url("../assets/Get_Involved_Plank.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100px;
}

#id-2:hover {
    transform: skewY(-10deg);
}

#id-3 {
    background: url("../assets/T-Shirts.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 80px;
}

#id-3:hover {
    transform: skewY(10deg);
}

.stream_button {
    background-image: url("../assets/planks_panda/Plank_2.png"); 
}

.stream_button button {
    padding: 10px;
    cursor: auto;
}

.schedule_button {
    background-image: url("../assets/planks_panda/Plank_1.png"); 
}

.donate_button {
    background-image: url("../assets/planks_panda/PayPal_plank.png"); 
}

.back {
    color: whitesmoke;
}

#id-5 {
    background: url("../assets/Calendar.png"); 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 300px;
}
