.container-test {
    display: grid;
    grid-template-columns: 1fr 3fr; /* 1/4 and 3/4 of the page */
    height: 100vh; /* Adjust the height as needed */
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
    }
    @media (max-height: 500px) { 
      grid-template-columns: 1fr
    }
  }

  .background-test-donate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/DonateBG.jpg") no-repeat center center fixed;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }

  .background-test-merch {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/MerchBG.jpg") no-repeat center center fixed;
    background-size: cover;
    background-position: center;
    z-index: -1;

  }

  .background-test-volunteer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/VolunteerBG.jpg") no-repeat center center fixed; 
    background-size: cover;
    background-position: center;
    z-index: -1;
    /* Add any other styles you want for the background */
  }

  .volunteer-scroll {
    width: 44rem!important;
    margin-bottom: -50px !important;
  }

  .background-test-dj {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/GetInvolvedBG_2.jpg") no-repeat center center fixed; 
    background-size: cover;
    background-position: center;
    z-index: -1;
    /* Add any other styles you want for the background */
  }
  
  .background-test-submit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/ArchiveBG.jpg") no-repeat center center fixed; 
    background-size: cover;
    background-position: center;
    z-index: -1;
    /* Add any other styles you want for the background */
  }

  .sidebar-test {
    margin-top:60px;
    @media (max-height: 500px) { 
      display: none;
    }
  }
  
  .content-test {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .merch-test {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .component-test {
    max-width: 75%;
    flex: 1;
    display: flex;
    flex-direction: column; 
    align-items: center;
  }

  .component-test img {
    width: 90%;
    margin-bottom:-25px;
    max-width: 75%;
    max-height: 500px;
    @media (max-width: 600px) {
      min-width: 300px;
    max-width: 300px;
    margin-top: 100px;
    }
  }


  .component-test-form {
    flex: 1; /* Take up 1/3rd of the available vertical space */
    display: flex;
    justify-content: center;
    align-items: center;
    
    background: whitesmoke;
    border: 1px solid black;
    padding: 1.5rem;
    width: 50%;
    
    padding: 1.5rem;
    
    border-radius: .5em;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
  }
  .first-block {
    display: flex;
    gap: 30px;
  }

  .second-block {
    display: flex;
    gap: 30px;
  }

  .volunteer-form {
    margin-top: 40px;
  }
  
  .component-test-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Adjust the width as needed */
    max-width: 400px; /* Set a maximum width if desired */
    
  }

  .component-test-form form input {
    width: 100%;
    height: 5px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }

  button[type="submit"] {
    margin-top: 10px;
    padding: 6px 12px;
    font-size: 14px;
  }
  
.example-psa-button {
    font-size: 1.5vw;
    border-radius: 20px;
    background-color: beige;
    padding: 5px;
    max-width: 500px;
    margin-top: 25px;
}

.modal {
    position: absolute;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid grey;
    border-radius: 20px;
    background-color: beige;
    padding: 20px;
    color: grey;
    font-size: 2vw;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 60px 10px 0px 10px;
    text-align: left;
}

.modal h1 {
    text-align: center;
}

.modal button {
    font-size: 1.5vw;
    border-radius: 20px;
    background-color: beige;
    padding: 5px;
}

.form-textarea {
    width: 100%;
    height: 60px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    transition: border-color 0.3s ease;
  }

  .donate-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    gap: 10px;
    @media (max-width: 600px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .donate-image img{
    max-width: 100%; 
    /* max-height: auto; */
    
    @media (max-width: 600px) {
      margin-top: 0px;
  }
  }

  .donate-component-test {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 75%;
    margin-top: -100px;
    @media (max-width: 700px) {

      display: none;
    }
  }

  .donate-component-test img {
    max-width: 75%;
    height: auto;
    margin-top: 100px;
    @media (max-width: 600px) {
    margin-top: 10px;
    }
  }

  .custom-donate {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Flexbox container for the images */
  .donate-image {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;                /* Add space between images */
    flex-wrap: nowrap;         /* Prevent images from wrapping */
    
    @media (max-width: 600px) {
      gap: 10px;               /* Adjust gap for smaller screens */
    }
  }
  
  #donate {
    margin-top: 5px;
    height: 300px;
    width: 350px;
    object-fit: contain;        /* Ensure the image scales within its container */
    
    @media (max-width: 600px) {
      margin-top: -50px;
      height: 200px;            /* Scale down for mobile */
      width: 230px;             /* Scale width for mobile */
    }
  }
  
  #paypal {
    height: 300px;
    width: auto;               /* Maintain aspect ratio */
    object-fit: contain;
    
    @media (max-width: 600px) {
      margin-top: -50px;
      height: 200px;
    }
  }
  
  #supporters {
    height: 250px;
    width: 250px;
    margin-top: -100px;
    margin-right: 10px;
    object-fit: contain;
  
    @media (max-width: 600px) {
      display: none;            /* Hide on mobile */
    }
  }

  .component-test-shop {
    display: flex;
    flex-direction: column; 
    align-items: center;
    margin-top: -75px;
    max-width: 75%;
  }

    .component-test-shop img {
    max-width: 75%;
    height: auto;
    margin-top: 100px;
    @media (max-width: 600px) {
    min-width: 300px;
    max-width: 300px;
    margin-top: 100px;
    }
  }

  .merch-1-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin-top: 25px;
  }

  #tee {
    width: 44rem;
  }

  .button-wood-plank-shop {
    width: 5px;
  }

  .listenMobileButton img {
      display: none;
      @media (max-width: 700px) {
        width: 200px;
          display: flex;
         
      }
  }


  @media screen and (max-width: 935px) and (max-height: 450px) and (orientation: landscape) {
    .landscape-message {
      display: block;
      font-size: 50px;
      text-align: center;
      color: gold;
    }
    .in-cont {
      display: none;
    }
  }