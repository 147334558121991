@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&family=Caveat+Brush&family=Francois+One&family=Indie+Flower&family=Limelight&family=Neucha&family=Roboto:ital@1&family=Rubik+Dirt&family=Rye&display=swap');


/* Homepage background */

.homepage {
  background: url("../assets/GetInvolvedBG.jpg") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media (max-width: 600px) {
    background-image: url("../assets/GetInvolvedBG.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.landscape-message {
  display: none;
}

.in-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  max-width: 900px;
  margin: auto;
  margin-top: 30px;
  
  @media (max-width: 600px) {
    padding: 0px;
  }
  @media screen and (max-height: 730px) {
   padding-bottom: 0px;
  }
}

.logo {
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    
    margin-top: -40px;
    width: 110%;
    height: 190px;
  }
  
}


/* logo */
.logo img {
  max-width: 100%;
  height: auto;
  
  @media (max-width: 600px) {
    min-width: 300px;
  max-width: 340px;
  }
}
/* buttons */
.buttons-home {
  max-width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 90px;
  
  @media (max-width: 600px) {
   flex-direction: column;
   gap: 70px;
  }
}

#plank{
margin-top: -30px;
max-width: 100%;
height: auto;
@media (max-width: 600px) { 
  margin-top: -70px;
  margin-left: 20px;
  width: 90%;
}
}

#plank:hover {
  transform: skewY(10deg);
}

#lastChild{
  margin-bottom: -80px;
  width: 100%;
}

button {
  font-size: 50px;
}

#amatic {
  font-family: 'Amatic SC', cursive;
  @media screen and (max-height: 730px) {
  
  }

  
}

.landscape-message {
  display: none;
}

@media screen and (max-width: 935px) and (max-height: 450px) and (orientation: landscape) {
      .landscape-message {
        display: block;
        font-size: 50px;
        text-align: center;
        color: gold;
      }
      .in-cont {
        display: none;
      }
    }

    @media screen and (max-width: 768px) and (orientation: portrait) {
      .landscape-message {
        display: none;
      }
      .in-cont {
        display: flex;
        flex-direction: column;
      }
    }