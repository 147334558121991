/* nonHoverFooter.css */
.footer-non-hover img {
  width: 125%; /* Make the image responsive */
  height: 400px; /* Maintain aspect ratio */
  
  margin-left: -100px;
}

/* Add media query for smaller screens (adjust breakpoint as needed) */
@media (max-width: 768px) {
  .footer-non-hover img {
    max-width: 300px;
  }
}